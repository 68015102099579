// cssをインポート
import "../../node_modules/destyle.css/destyle.css";
import "../sass/style.scss";

import React from 'react';
import { createRoot } from 'react-dom/client';
import Setunset from "./setUnset";

import _ from 'lodash';

if ( $('#js-setunset-root').length ) {
	const setunsetRoot = createRoot(document.getElementById('js-setunset-root'));
	setunsetRoot.render(<Setunset />);
}

require('./common');
