import React from 'react';

export const Filter = (props) => {
  const {
    base,
    settings,
    filterPlatformSelected,
    orderChangeRowChecked,
    filterChangeRowChecked,
    filterText,
    setFilterPlatform,
    setOrderChangeRow,
    setFilterChangeRow,
    setFilterText
  } = props;

  function platformChange(e) {
    const tmp = _.clone(filterPlatformSelected);
    tmp[base] = e.target.value;
    setFilterPlatform(tmp);
  }
  function orderChangeRowChange(e) {
    const tmp = _.clone(orderChangeRowChecked);
    tmp[base] = !tmp[base];
    setOrderChangeRow(tmp);
  }
  function filterChangeRowChange(e) {
    const tmp = _.clone(filterChangeRowChecked);
    tmp[base] = !tmp[base];
    setFilterChangeRow(tmp);
  }
  function filterTextInputChange(e) {
    const tmp = _.clone(filterText);
    tmp[base] = e.target.value;
    setFilterText(tmp);
  }

  return (
      <div className="search__detail_filter-wrap">
        <div className="search__detail_filter">
          <p>テキストフィルタ</p>
          <input type="text" onChange={filterTextInputChange} ></input>
        </div>
        {(settings.mode == 'ad')? (
        <div className="search__detail_filter">
          <p>プラットフォーム</p>
          <div className="input-wrap">
          	<input type="radio" id={'filter-pl-all-'+base} name={'filter-platform-'+base} value="all" onChange={platformChange} checked={(filterPlatformSelected[base]=='all')}></input><label htmlFor={'filter-pl-all-'+base}>すべて</label>
          	<input type="radio" id={'filter-pl-ios-'+base} name={'filter-platform-'+base} value="iOS" onChange={platformChange} checked={(filterPlatformSelected[base]=='iOS')}></input><label htmlFor={'filter-pl-ios-'+base}>iOS</label>
           	<input type="radio" id={'filter-pl-android-'+base} name={'filter-platform-'+base} value="Android" onChange={platformChange} checked={(filterPlatformSelected[base]=='Android')}></input><label htmlFor={'filter-pl-android-'+base}>Android</label>
          </div>
        </div>
        ) : "" }
        <div className="search__detail_filter">
          <p>その他</p>
          <div className="input-wrap">
          	<input type="checkbox" id={'order-change-'+base} name={'order-change-'+base} checked={orderChangeRowChecked[base]} onChange={orderChangeRowChange}></input><label htmlFor={'order-change-'+base}>{base=="setting"?'追加':'解除'}分を先頭に表示</label>
          	<input type="checkbox" id={'filter-change-'+base} name={'filter-change-'+base} checked={filterChangeRowChecked[base]} onChange={filterChangeRowChange}></input><label htmlFor={'filter-change-'+base}>{base=="setting"?'追加':'解除'}分のみ表示</label>
          </div>
        </div>
      </div>
  );
};
