jQuery(function ($) {

	if ( $('#regi-form').length ) {
		$('.regi-form-submit').on('click',function(){
			const fetch_url = $(this).attr('data-check-url');
			const regi_id = ($('input[name="ad_regi_id"]').length)? $('input[name="ad_regi_id"]').val(): '';
			const game_id = $('select[name="game_id"]').val();
			const platform_id = $('select[name="platform"]').val();

			const sendData = {
				'regi_id' : Number(regi_id),
				'game_id' : Number(game_id),
				'platform_id' : Number(platform_id),
			};
			const sendDataJson = JSON.stringify(sendData);

			console.log(sendDataJson);

    		fetch(fetch_url, {
    		  method: "POST",
    		  body: sendDataJson,
    		  headers: {
    		    Accept: "application/json", 
    		  },
    		})
    		.then((response) => response.json()).then((data) => {
    		  console.log(data);
			  if ( data.result == 'OK' ) {
				console.log('OK');
				$('#regi-form').submit();
			  } else {
				$('.error-message').text(data.message);
			  }
    		})
    		.catch((error) => {
    		  console.log(error);
    		});

			return false;
		})
	}

})