import _ from 'lodash';
import React, {useState,useEffect} from 'react';
import { Filter } from "./components/Filter";
import { DataTable } from "./components/DataTable";

const Setunset = () => {

  //Jsonから未設定データを取得
  const unsettingJson_data = JSON.parse(document.querySelectorAll('#unsetting-json')[0].textContent);
  //Jsonから設定中データを取得
  const settingJson_data = JSON.parse(document.querySelectorAll('#setting-json')[0].textContent);
  //Jsonから設定情報を取得（registration主軸かad主軸かのモード、主軸となるregistrationまたはadデータ、表示カラムの情報）
  const settings = JSON.parse(document.querySelectorAll('#settings')[0].textContent);

  //未設定データの要素追加、編集
  const getUnsetData = (data) => {
    return _.map(data,(obj)=>{
      //未設定データであることを示す区分
      obj.base = 'unsetting';
      //名称未設定の場合全角スペースを入れておく
      obj.name = ( obj.name )? obj.name :'　';
      //idを数字にしておく
      obj.id = Number(obj.id);
      //変更有無でソートする用の項目追加
      obj.change = 0;
      return obj;
    });
  }
  //設定中データの要素追加、編集
  const getSetData = (data) => {
    return _.map(data,(obj)=>{
      //設定中データであることを示す区分
      obj.base = 'setting';
      //名称未設定の場合全角スペースを入れておく
      obj.name = ( obj.name )? obj.name :'　';
      //idを数字にしておく
      obj.id = Number(obj.id);
      //変更有無でソートする用の項目追加
      obj.change = 0;
      return obj;
    });
  }

  // state登録
  const [unsetDataState, setUnset] = useState(getUnsetData(unsettingJson_data)); //未設定データ
  const [setDataState, setSet] = useState(getSetData(settingJson_data)); //設定データ
  const [newUnsetData, setNewUnset] = useState([]); //更新処理後表示用未設定データ
  const [newSetData, setNewSet] = useState([]); //更新処理後表示用設定データ
  const [unsetColumnData, setUnsetColumnData] = useState(_.cloneDeep(settings.columns)); //未設定データの表示カラム情報
  const [setColumnData, setSetColumnData] = useState(_.cloneDeep(settings.columns)); //設定データの表示カラム情報
  const [filterPlatformSelected, setFilterPlatform] = useState({'unsetting':'all','setting':'all'}); //プラットフォームのフィルター情報
  const [orderChangeRowChecked, setOrderChangeRow] = useState({'unsetting':true,'setting':true}); //「変更分を先頭に表示する」のチェックボックス情報
  const [filterChangeRowChecked, setFilterChangeRow] = useState({'unsetting':false,'setting':false}); //「変更分のみ表示」のチェックボックス情報
  const [filterText, setFilterText] = useState({'unsetting':'','setting':''}); //テキストフィルターの情報
  const [selectedRowCount, setSelectedRowCount] = useState({'unsetting':0,'setting':0}); //選択行数のカウント
  const [windowName, setWindowName] = useState('detail'); //現在の画面の形態（detail,confirm,error,complete）
  const [refleshTable, setRefleshTable] = useState(false);
  const [updatingNow, setUpdatingNow] = useState(false);

  //選択中の行数を数え直す
  useEffect(()=>{
    updateSelectedRowCount('unsetting');
    updateSelectedRowCount('setting');
  })

  //refleshTableをオンの状態での描画完了後にオフにする
  useEffect(()=>{
    if ( refleshTable ) {
      setRefleshTable(false);
    }
  },[refleshTable]);

  const init_state = () => {
    setFilterPlatform({'unsetting':'all','setting':'all'});
    setOrderChangeRow({'unsetting':true,'setting':true});
    setFilterChangeRow({'unsetting':false,'setting':false});
    setFilterText({'unsetting':'','setting':''});
    setSelectedRowCount({'unsetting':0,'setting':0});
    setWindowName('detail');
    setRefleshTable(false);
    setUpdatingNow(false);
  }

  // 設定行きボタン
  const onClickSetting = () => {
    // 複製
    let newUnsetData = _.clone(unsetDataState);
    let newSetData = _.clone(setDataState);

    // activeの要素を移動
    const checkUnsetList = document.querySelectorAll('.search__detail_unsetting .active');
    checkUnsetList.forEach(checkUnset => {
      let move_data = _.remove(newUnsetData, (obj) => { return obj.id === Number(checkUnset.dataset.id); });
      move_data = _.map(move_data,(obj)=>{
        obj.change = (obj.base=='unsetting')? 1 : 0;
        return obj;
      });
      newSetData = _.union(newSetData,move_data);
    });

    //反映
    setSet(newSetData);
    setUnset(newUnsetData);

    //setting側の選択を解除
    //allClear('setting');
  };

  // 未設定行きボタン
  const onClickUnsetting = () => {
    // 複製
    let newUnsetData = _.clone(unsetDataState);
    let newSetData = _.clone(setDataState);

    // activeの要素を移動
    const checkSetList = document.querySelectorAll('.search__detail_setting .active');
    checkSetList.forEach(checkSet => {
      let move_data = _.remove(newSetData, (obj) => { return obj.id === Number(checkSet.dataset.id); });
      move_data = _.map(move_data,(obj)=>{
        obj.change = (obj.base=='setting')? 1 : 0;
        return obj;
      });
      newUnsetData = _.union(newUnsetData,move_data);
    });

    //反映
    setSet(newSetData);
    setUnset(newUnsetData);

    //unsetting側の選択を解除
    //allClear('unsetting');
  };

  //表の選択をすべて解除する
  const allClear = (base,filter = false ) => {
    const checkList = document.querySelectorAll('.search__detail_' + base + ' tbody tr');
    checkList.forEach(elem => {
      if ( elem.classList.contains('active') ) {
        if ( !(filter && elem.classList.contains('js-no-hit')) ){
          elem.classList.remove('active');
        }
      }
    });
    updateSelectedRowCount(base);
  }

  //選択中行数の更新
  const updateSelectedRowCount = (base) => {
    const tmp = _.clone(selectedRowCount);
    const selectedRowList = document.querySelectorAll('.search__detail_'+ base + ' .active');
    tmp[base] = selectedRowList.length;
    if ( tmp[base] != selectedRowCount[base] ) {
      setSelectedRowCount(tmp)
    }
  }

  //テーブル表示用の配列を表示用にソート、フィルターして返す
  const getDisplayData = (base) => {
    const columns = (base == 'setting')? setColumnData: unsetColumnData;
    const rows = (base == 'setting')? setDataState: unsetDataState;
    let displayRows = [];
    if ( refleshTable ) {
      //refleshTableがtrueのときはdisplayRowsを空で返す
      const dataInfo = {
        'displayRows' : displayRows,
        'allRows' : rows,
      }
      return dataInfo;
    }
    displayRows = commonSort(rows,columns,base);
    if ( ( filterChangeRowChecked[base] && windowName=='detail' ) ) {
      //変更分でフィルター
      const base_invert = (base=='setting')?'unsetting':'setting';
      displayRows = _.filter(displayRows,{'base':base_invert});
    }
    if ( windowName == 'detail' ) {
      if ( filterPlatformSelected[base] != 'all' ) {
        //プラットフォームでフィルター
        displayRows = _.filter(displayRows,{'platform_name':filterPlatformSelected[base]});
      }
      if ( filterText[base] ) {
        const search_text = filterText[base].toLowerCase();
        //テキストでフィルター
        displayRows = _.filter(displayRows,(obj)=>{
          let hitFlg = false;
          _.each(obj,(value,key)=>{
            const column_value = String(value).toLowerCase();
            if ( _.includes(column_value,search_text) )  {
              hitFlg = true
            }
          });
          return hitFlg;
        });
      }
    }
    const dataInfo = {
      'displayRows' : displayRows,
      'allRows' : rows,
    }
    return dataInfo;
  }

  //未設定データまたは設定中データをソートする
  function commonSort(sortList,columns,base){
    let keys = [];
    let orders = [];

    //変更分を先頭に表示チェックボックスがオンの場合、変更分を優先するソートキー追加
    const change_top = orderChangeRowChecked[base];
    if ( change_top ||  windowName != 'detail' ) {
      keys.push('change');
      orders.push('desc');
    }

    if ( columns ) {
      _.each(columns,(obj)=>{
        if ( obj.order != 'none' ) {
          keys.push(obj.column_id);
          orders.push(obj.order);
        }
      });
    } else {
      keys.push('id');
      orders.push('asc');
    }
    return _.orderBy(sortList,keys,orders);
  }

  //戻るボタン押下時処理
  function page_back() {
    //window.history.back();
    location.href = settings.back_url;
    return false
  }

  //決定ボタン押下時処理
  const click_send = () => {
    allClear('setting');
    allClear('unsetting');
    setWindowName('confirm');
    setRefleshTable(true);
    return false;
  }

  //確認画面キャンセルボタン押下時処理
  const click_confirm_cancel = () => {
    setWindowName('detail');
    setRefleshTable(true);
    return false;
  }

  //確認画面OKボタン押下時処理
  const click_confirm_ok = () => {
    //送信処理
    submitUpdate();
    return false;
  }

  //リロードボタン押下時処理
  const click_reload = () => {
    setUnset(_.cloneDeep(newUnsetData));
    setSet(_.cloneDeep(newSetData));
    init_state();
    //new_unsetting_data = [];
    //new_setting_data = [];
    return false;
  }

  //送信処理
  const submitUpdate = () => {
    const sendData =getSendData();
    const sendDataJson = JSON.stringify(sendData);
    setUpdatingNow(true);
    fetch(settings.fetch_url, {
      method: "POST",
      body: sendDataJson,
      headers: {
        Accept: "application/json", 
      },
    })
    .then((response) => response.json()).then((data) => {
      console.log(data);
      setNewUnset(getUnsetData(data.new_unsetting));
      setNewSet(getSetData(data.new_setting));
      setWindowName('complete');
      setRefleshTable(true);
      setUpdatingNow(false);
    })
    .catch((error) => {
      console.log(error);
      setWindowName('error');
      setRefleshTable(true);
      setUpdatingNow(false);
    });
  }

  //送信データ作成
  const getSendData = () => {
    const add_data = _.filter(setDataState,{'base':'unsetting'});
    const delete_data = _.filter(unsetDataState,{'base':'setting'});
    const sendData = {
      'mode' : settings.mode,
      'main_data' : settings.main_data,
      'add_link_data' : _.orderBy(add_data,['id'],['asc']),
      'delete_link_data' : _.orderBy(delete_data,['id'],['asc']),
      'setting_data' : _.orderBy(setDataState,['id'],['asc']),
    };
    return sendData;
  }

  //設定ボタンの活性非活性
  const addButtonDisabled = (selectedRowCount['unsetting'] == 0)? 'disabled' : '' ;
  //解除ボタンの活性非活性
  const clearButtonDisabled = (selectedRowCount['setting'] == 0)? 'disabled' : '' ;
  //送信ボタンの活性非活性
  const sendButtonDisabled = (_.some(setDataState,{'base':'unsetting'}) || _.some(unsetDataState,{'base':'setting'}) )? false: true;

  //確認画面のメッセージ
  const confirmMessage = () => {
    let ret = (
      <p className="message">
        以下の内容で更新します。よろしいですか？
      </p>
    )
    if ( windowName=='complete' ){
      ret = (
        <p className="message is-complete">
          更新が完了しました
        </p>
      )
    } else if ( windowName=='error' ) {
      ret = (
        <p className="message is-error">
          更新処理でエラーが発生しました
        </p>
      )
    } else if ( updatingNow ) {
      ret = (
        <p className="message is-updating">
          更新中です
        </p>
      )
    }
    return ret;
  }

  window.onbeforeunload = function(event){
    if ( ! sendButtonDisabled ) {
      event = event || window.event; 
      event.returnValue = '入力中のページから移動しますか？';
    }
  }

  return (
    <div className="search__detail_wrapper">
      {( windowName=='detail' )?
      <div className="search__detail_head">
        <Filter
          base = 'unsetting'
          settings = {settings}
          filterPlatformSelected = {filterPlatformSelected} 
          orderChangeRowChecked = {orderChangeRowChecked}
          filterChangeRowChecked = {filterChangeRowChecked} 
          filterText = {filterText}
          setFilterPlatform = {setFilterPlatform}
          setOrderChangeRow = {setOrderChangeRow}
          setFilterChangeRow = {setFilterChangeRow}
          setFilterText = {setFilterText}
        />
        <div className="search__detail_head-space-dummy">
        </div>
        <Filter
          base = 'setting'
          settings = {settings}
          filterPlatformSelected = {filterPlatformSelected} 
          orderChangeRowChecked = {orderChangeRowChecked}
          filterChangeRowChecked = {filterChangeRowChecked} 
          filterText = {filterText}
          setFilterPlatform = {setFilterPlatform}
          setOrderChangeRow = {setOrderChangeRow}
          setFilterChangeRow = {setFilterChangeRow}
          setFilterText = {setFilterText}
        />
      </div>
      : 
      <div className="search__detail_head">
        {confirmMessage()}
      </div>
      }
      <div className="search__detail_body">
        <DataTable
          base = 'unsetting'
          columnData = {unsetColumnData}
          selectedRowCount = {selectedRowCount}
          getDisplayData = {getDisplayData}
          setColumnData = {setUnsetColumnData}
          updateSelectedRowCount = {updateSelectedRowCount}
          windowName = {windowName}
          updatingNow = {updatingNow}
        />
        {( windowName=='detail' )?
        <div className="search__detail_move">
          <p className={addButtonDisabled} onClick={onClickSetting}>設定→</p>
          <p className={clearButtonDisabled} onClick={onClickUnsetting}>←解除</p>
        </div>
        : 
        <div className="search__detail_move">
        </div>
        }
        <DataTable
          base = 'setting'
          columnData = {setColumnData}
          selectedRowCount = {selectedRowCount}
          getDisplayData = {getDisplayData}
          setColumnData = {setSetColumnData}
          updateSelectedRowCount = {updateSelectedRowCount}
          windowName = {windowName}
          updatingNow = {updatingNow}
        />
      </div>

      {( windowName=='detail' )?
      <div className="search__detail_footer">
        {( settings.back_url )?
        <button onClick={page_back}>戻る</button>
        : ''}
        <button onClick={click_send} disabled={sendButtonDisabled}>決定</button>
      </div>
      : ''}

      {( windowName=='confirm' || windowName=='error' )?
      <div className="search__detail_footer">
        <button onClick={click_confirm_cancel} disabled={updatingNow}>キャンセル</button>
        <button onClick={click_confirm_ok} disabled={updatingNow}>更新実行</button>
      </div>
      : ''}

      {( windowName=='complete' )?
      <div className="search__detail_footer">
        <button onClick={click_reload}>OK</button>
      </div>
      : ''}

    </div>
  )
}

export default Setunset;
