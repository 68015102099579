import React from "react";

export const DataTable = (props) => {
  const {
    base,
    columnData,
    getDisplayData,
    setColumnData,
    updateSelectedRowCount,
    selectedRowCount,
    windowName,
    updatingNow,
  } = props;

  const dataInfo = getDisplayData(base);
  const displayRows = dataInfo.displayRows;
  const allRows = dataInfo.allRows;

  //全選択解除ボタンの活性非活性
  const allClearButtonDiabled = (selectedRowCount[base] == 0)? 'disabled' : '' ;

  const dataRowClass = (windowName == 'detail')? '' : 'not-action';

  //全選択
  const allSelectBtnClick = (e) => {
    e.stopPropagation();
    const checkList = document.querySelectorAll('.search__detail_' + base + ' tbody tr');
    checkList.forEach(elem => {
      if ( ! elem.classList.contains('active') ) {
        if ( !(elem.classList.contains('js-no-hit')) ){
          elem.classList.add('active');
        }
      }
    });
    updateSelectedRowCount(base);
  }

  //全選択解除
  const allClearBtnClick = (e) => {
    e.stopPropagation();
    const checkList = document.querySelectorAll('.search__detail_' + base + ' tbody tr');
    checkList.forEach(elem => {
      if ( elem.classList.contains('active') ) {
        if ( !(elem.classList.contains('js-no-hit')) ){
          elem.classList.remove('active');
        }
      }
    });
    updateSelectedRowCount(base);
  }

  //テーブルヘッダー
  const getTableHeader = () => {
    let columnHead = [];
    _.each(columnData,(obj) => {
      columnHead.push(
        <th key={obj.column_id} className={'is-sortable ' + obj.order} data-columnid={obj.column_id} data-order={obj.order} style={{width:(obj.width)? obj.width : '100%'}} onClick={dataSort}>
          <span>{obj.label}</span>
        </th>
      );
    });
    return columnHead;
  }

  //テーブルデータ
  const getTableData = () => {
    let rowBody = [];
    _.each(displayRows,(row) => {
      let columnBody = [];
      _.each(columnData,(obj) => {
        columnBody.push(
          <td key={obj.column_id} style={{width:(obj.width)? obj.width : '100%'}}>{getColumnStr(row[obj.column_id],obj.column_id)}</td>
        );
      });
      rowBody.push(
        <tr key={row.id} className={(row.base != base ? "js-strong" : "") + ' ' + (dataRowClass)} data-id={row.id} data-base={row.base} onClick={BtnClick}>
          {columnBody}
        </tr>
      );
    });
    return rowBody;
  }

  const getColumnStr = (value,column_id) => {
    let ret;
    if ( column_id == 'created_at') {
      //日付部分のみ切り取り
      ret = value.substr(0,10);
    } else {
      ret = value
    }
    return ret;
  }

  //行の選択状態切り替え
  const BtnClick = (e) => {
    if ( windowName!='detail' ) {
      return
    }
    //クリックした行を選択状態を切り替える
    if( e.currentTarget.classList.contains('active') ) {
      e.currentTarget.classList.remove('active');
    }else{
      e.currentTarget.classList.add('active');
    }
    updateSelectedRowCount(base);
  }

  //ソート情報更新
  const dataSort = (e) => {
    if ( updatingNow ) {
      //更新中はソート変更させない
      return;
    }
    const column_id = e.currentTarget.dataset.columnid;
    const order = (e.currentTarget.dataset.order=='asc')? 'desc' : 'asc';
    const newColumnData = _.map(columnData,(obj)=>{
      if ( obj.column_id == column_id ) {
        obj.order = order;
      } else {
        obj.order = 'none';
      }
      return obj;
    });
    setColumnData(newColumnData);
  }

  //変更件数取得
  const getChangeDataCount = () => {
    const changeRows = _.filter(allRows,(row)=> row.base != base);
    return changeRows.length;
  }

  const tableHeadLabel = () => {
    if ( windowName=='detail' ) {
      if ( base == "setting") {
        return "設定中";
      } else {
        return "未設定";
      }
    } else {
      let allCount = allRows.length;
      let changeCount = getChangeDataCount();
      if ( base == "setting") {
        return "設定："+ allCount + "件（追加：" + changeCount + "件、継続：" + (allCount - changeCount) + "件）";
      } else {
        return "解除：" + changeCount + "件";
      }
    }
  }

  return (
    <>
    <div className={'search__detail_'+base}>
      <table>
        <thead>
          <tr>
            {( windowName=='detail' )?
            <th>
              {tableHeadLabel()}
              <p className='all-select-button' onClick={allSelectBtnClick}>全選択</p>
              <p className={'all-clear-button ' + allClearButtonDiabled} onClick={allClearBtnClick}>選択全解除</p>
              <p className='display-count'>{displayRows.length}／{allRows.length} 件表示</p>
            </th>
            :
            <th>
              {tableHeadLabel()}
            </th>
            }
          </tr>
          <tr>
            {getTableHeader()}
          </tr>
        </thead>
        <tbody>
        {getTableData()}
        </tbody>
      </table>
    </div>
    </>
  );
};
